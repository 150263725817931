import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 5

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.project.isAccess
}
export const guardProjectCreation = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.createAccess ? user.profile.modules.project.createAccess : { name: 'Home' }
}
export const guardPlanningResource = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.planningResourceAccess ? user.profile.modules.project.planningResourceAccess : { name: 'Home' }
}
export const guardStats = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.statsAccess ? user.profile.modules.project.statsAccess : { name: 'Home' }
}
export const guardSettings = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.settingsAccess ? user.profile.modules.project.settingsAccess : { name: 'Home' }
}

export const guardEnterProject = () => {
  const userStore = useUserStore()
  const user = userStore.user

  return user.profile.modules.project.overviewOnlyPublic === false
}

export const guardProjectAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const accessStaffing = (project) => {
  const userStore = useUserStore()
  const user = userStore.user

  const isAdmin = (user._id === project.directorId || user._id === project.managerId || project.contributors.some(contributor => contributor.contributorId === user._id))
  if (isAdmin && user.profile.modules.project.onAdminProject.staffing)
    return true

  if (user.profile.modules.project.overviewOnlyPublic === false && user.profile.modules.project.onGlobalAccess.staffing)
    return true

  return { name: 'Home' }
}

export const accessBudget = (project) => {
  const userStore = useUserStore()
  const user = userStore.user

  const isAdmin = (user._id === project.directorId || user._id === project.managerId || project.contributors.some(contributor => contributor.contributorId === user._id))
  if (isAdmin && user.profile.modules.project.onAdminProject.budget)
    return true

  if (user.profile.modules.project.overviewOnlyPublic === false && user.profile.modules.project.onGlobalAccess.budget)
    return true

  return { name: 'Home' }
}

export const accessMonitoring = (project) => {
  const userStore = useUserStore()
  const user = userStore.user

  const isAdmin = (user._id === project.directorId || user._id === project.managerId || project.contributors.some(contributor => contributor.contributorId === user._id))
  if (isAdmin && user.profile.modules.project.onAdminProject.monitoring)
    return true

  if (user.profile.modules.project.overviewOnlyPublic === false && user.profile.modules.project.onGlobalAccess.monitoring)
    return true

  return { name: 'Home' }
}

export const accessTimesheet = (project) => {
  const userStore = useUserStore()
  const user = userStore.user

  const isAdmin = (user._id === project.directorId || user._id === project.managerId || project.contributors.some(contributor => contributor.contributorId === user._id))
  if (isAdmin && user.profile.modules.project.onAdminProject.timesheet)
    return true

  if (user.profile.modules.project.overviewOnlyPublic === false && user.profile.modules.project.onGlobalAccess.timesheet)
    return true

  return { name: 'Home' }
}

export const accessEdit = (project) => {
  const userStore = useUserStore()
  const user = userStore.user

  const isAdmin = (user._id === project.directorId || user._id === project.managerId || project.contributors.some(contributor => contributor.contributorId === user._id))
  if (isAdmin && user.profile.modules.project.onAdminProject.editProject)
    return true

  if (user.profile.modules.project.overviewOnlyPublic === false && user.profile.modules.project.onGlobalAccess.editProject)
    return true

  return { name: 'Home' }
}
